import axios from 'axios';
import Helmet from 'react-helmet';
import React, { Component } from 'react';

import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import InRiverProcessor from '../../../../plugins/gatsby-source-inriver/InRiverProcessor';
import ProductsList from '../products/products/ProductsList';
import ProductModel from '../../models/Product';
import SiteLoader from '../_common/SiteLoader';
import withKlaviyo from '../../containers/plugins/withKlaviyo';

class Search extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      loading: false,
      products: [],
    };

    this.state = { ...this.initialState };

    this.doQuery = this.doQuery.bind(this);
  }

  componentDidMount() {
    const { klaviyo, search } = this.props;

    this.doQuery();

    const query = new URLSearchParams(search).get('q');
    klaviyo.searchedFor('Main Search', query);
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search) {
      this.doQuery();
    }
  }

  async doQuery() {
    const { site } = this.props;

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const query = new URLSearchParams(this.props.search).get('q');

    const results = await axios
      .get(`/api/products/${site.siteUID}/search?q=${query}`)
      .then((res) => res.data?.data || []);

    const products = [];

    for (const result of results) {
      const processed = InRiverProcessor.processProduct(result);
      products.push(new ProductModel(processed, site.siteUID, site));
    }

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
      products: products,
    }));
  }

  render() {
    const { breadcrumbs, site } = this.props;
    const query = new URLSearchParams(this.props.search).get('q');

    return (
      <article className="page article search">
        <Helmet title={`"${query}"`} />
        <Breadcrumbs breadcrumbs={breadcrumbs} site={site} />

        <header className="article__header">
          <div className="article__featured">
            <div className="article__featured-text">
              <h1 className="article__title">"{query}"</h1>
            </div>
          </div>
        </header>
        <div className="article__section">
          {this.state.loading ? (
            <SiteLoader />
          ) : (
            <ProductsList
              id="search"
              products={this.state.products}
              site={site}
            />
          )}
        </div>
      </article>
    );
  }
}

export default withKlaviyo(Search);
