const slugify = require('slugify');

class InRiverProcessor {
  static processChannel(inRiverChannel) {
    const { _id, name, shopifyAdminAPIURL, ...props } = inRiverChannel;
    return props;
  }

  static processProduct(inRiverProduct) {
    const fieldNames = [
      'ProductBadge',
      'ProductBrand',
      'ProductCategory',
      'ProductCancerReprTox',
      'ProductChemicals',
      'ProductHookBarbType',
      'ProductHookEye',
      'ProductHookEyeAngle',
      'ProductHookEyeTurn',
      'ProductHookFinish',
      'ProductHookLength',
      'ProductHookPoint',
      'ProductHookShank',
      'ProductHookShape',
      'ProductHookSubPoint',
      'ProductHookSubShank',
      'ProductHookTrademark',
      'ProductHookWeighted',
      'ProductHookWideGap',
      'ProductHookWire',
      'ProductHookWireStrength',
      'ProductLocation',
      'ProductLongDescription',
      'ProductLureDepthDive',
      'ProductLureMaterial',
      'ProductLureTool',
      'ProductLureType',
      'ProductLureSpecies',
      'ProductName',
      'ProductProp65Comment',
      'ProductSeason',
      'ProductSegmentationBait',
      'ProductSegmentationSpecies',
      'ProductSegmentationTechnique',
      'ProductSegmentationWaterType',
      'ProductSeries',
      'ProductSizeChart',
      'ProductSizeStyle',
      'ProductStiffness',
      'ProductSubCategory',
      'ProductShortDescription',
      'ProductTrademark',
      'ProductVariantSelector',
      'ProductVideoUrl1',
      'ProductVideoUrl2',
      'ProductWarningType',
      'ProductWaterbodyType',
      'ProductWeedless',
      'ProductYotpoReviewCount',
      'ProductYotpoReviewScore',
    ];

    const fieldValues = fieldNames.reduce((acc, fieldName) => {
      acc[fieldName] = inRiverProduct.fields[fieldName];
      return acc;
    }, {});

    const resources = inRiverProduct.resources
      .filter((resource) => resource)
      .map((resource) => InRiverProcessor.processResource(resource));

    // prettier-ignore
    const prices = [
      { price: 'ItemWebshopPriceUS', compareAtPrice: 'ItemCompareAtPriceUS' },
      { price: 'ItemWebshopPriceCanada', compareAtPrice: 'ItemCompareAtPriceCanada' },
      { price: 'ItemWebshopPriceEuro', compareAtPrice: 'ItemCompareAtPriceEuro' },
      { price: 'ItemWebshopPriceNorway', compareAtPrice: 'ItemCompareAtPriceNorway' },
    ]

    const productPrices = {};
    const priceOk = (price) => price > 0 && price !== 88888;
    for (const price of prices) {
      if (!inRiverProduct.items) break;

      const webshopPrices = inRiverProduct.items.map((item) => {
        const newPrice = parseFloat(
          item.fields[price.price]?.value?.value || '0.00'
        );
        if (priceOk(newPrice)) {
          return newPrice;
        } else {
          return '0.00';
        }
      });

      const compareAtPrices = inRiverProduct.items
        .map((item) =>
          parseFloat(item.fields[price.compareAtPrice]?.value?.value || '0.00')
        )
        .filter((price) => priceOk(price));

      productPrices[price.price] = Math.min(...webshopPrices);
      productPrices[price.compareAtPrice] = Math.min(...compareAtPrices);

      if (!isFinite(productPrices[price.price])) {
        productPrices[price.price] = null;
      }

      if (!isFinite(productPrices[price.compareAtPrice])) {
        productPrices[price.compareAtPrice] = null;
      }
    }

    const quantitySoldFields = [
      { quantitySold: 'ItemQuantitySoldCA' },
      { quantitySold: 'ItemQuantitySoldEU' },
      { quantitySold: 'ItemQuantitySoldUS' },
    ];
    const quantitySolds = {};
    for (const quantitySoldField of quantitySoldFields) {
      if (!inRiverProduct.items) break;

      const quantitySold =
        inRiverProduct.items.map(
          (item) =>
            item.fields[quantitySoldField.quantitySold]?.value?.value || 0
        ) || [];

      quantitySolds[quantitySoldField.quantitySold] = quantitySold.reduce(
        (acc, field) => acc + field,
        0
      );
    }

    const inventoryFields = [
      { inventory: 'ItemInventoryUMW02' },
      { inventory: 'ItemInventoryPMW01' },
    ];
    const productInventory = {};
    for (const inventoryField of inventoryFields) {
      if (!inRiverProduct.items) break;

      const inventory = inRiverProduct.items.map(
        (item) => item.fields[inventoryField.inventory]?.value.value || 0
      );
      productInventory[inventoryField.inventory] = inventory.reduce(
        (acc, inv) => acc + inv,
        0
      );
    }

    const launchWindows = Array.isArray(inRiverProduct.items)
      ? Array.from(
          new Set(
            inRiverProduct.items.map(
              (item) => item.fields['ItemLaunchWindow']?.value?.key || ''
            )
          )
        ).filter((value) => value)
      : [];

    return {
      ...fieldValues,
      entityId: inRiverProduct.entityId,
      id: inRiverProduct.entityId,
      inventory: productInventory,
      launchWindows: launchWindows,
      path: inRiverProduct.fields.ProductSeries?.value?.value
        ? slugify(
            inRiverProduct.fields.ProductSeries.value.value.replace(/\//g, '-'),
            {
              lower: true,
              replacement: '-',
              remove: /[*+~.()'"!:@]/g,
            }
          )
        : null,
      prices: productPrices,
      quantitySolds: quantitySolds,
      relatedProducts: inRiverProduct.relatedProducts,
      resources: resources,
      searchKeys: inRiverProduct.searchKeys || '',
      shopId: inRiverProduct.shopId || null,
    };
  }

  static processVariant(inRiverVariant) {
    const fieldNames = [
      'ItemColor',
      'ItemColorName',
      'ItemCompareAtPriceCanada',
      'ItemCompareAtPriceEuro',
      'ItemCompareAtPriceNorway',
      'ItemCompareAtPriceUS',
      'ItemDiameterImperial',
      'ItemDiameterMetric',
      'ItemDiameterSize',
      'ItemEAN',
      'ItemFamilyName',
      'ItemGapMetric',
      'ItemHookSize',
      'ItemInventoryPMW01',
      'ItemInventoryUMW02',
      'ItemLaunchWindow',
      'ItemLengthImperial',
      'ItemLengthMetric',
      'ItemLengthSize',
      'ItemLureDepth',
      'ItemPackCode',
      'ItemPackQuantity',
      'ItemRodAction',
      'ItemRodLineClassSize',
      'ItemRodLureWeightSize',
      'ItemRodPower',
      'ItemRodSections',
      'ItemRodStyle',
      'ItemRodTransportLengthSize',
      'ItemQuantitySoldCA',
      'ItemQuantitySoldEU',
      'ItemQuantitySoldUS',
      'ItemSize',
      'ItemSizeSort',
      'ItemSizeStyle',
      'ItemSKUID',
      'ItemSkuName',
      'ItemShowWebAmericas',
      'ItemShowWebCanada',
      'ItemShowWebEurope',
      'ItemShowWebJapan',
      'ItemShowWebNorway',
      'ItemShowWebUSA',
      'ItemStyleName',
      'ItemTestImperial',
      'ItemTestMetric',
      'ItemTestRatingSize',
      'ItemWebshopPriceCanada',
      'ItemWebshopPriceEuro',
      'ItemWebshopPriceNorway',
      'ItemWebshopPriceUS',
      'ItemWeightImperial',
      'ItemWeightSize',
      'ItemUPC',
    ];

    const fieldValues = fieldNames.reduce((acc, fieldName) => {
      acc[fieldName] = inRiverVariant.fields[fieldName];
      return acc;
    }, {});

    const resources = inRiverVariant.media
      .filter((media) => media)
      .map((media) => InRiverProcessor.processResource(media));

    return {
      ...fieldValues,
      entityId: inRiverVariant.entityId,
      id: inRiverVariant.entityId,
      resources: resources,
      shopId: inRiverVariant.shopId || null,
    };
  }

  static processResource(inRiverResource) {
    if (typeof inRiverResource === 'string') {
      return {
        id: '',
        displayName: '',
        imageMap: null,
        resourceUrl: inRiverResource,
        sortKey: inRiverResource.sortKey || 0,
      };
    } else if (typeof inRiverResource === 'object') {
      return {
        id: inRiverResource?.entityId || null,
        displayName: inRiverResource?.entityId || null,
        resourceUrl: inRiverResource.fields?.ResourceUrl || null,
        sortKey: inRiverResource.sortKey || 0,
      };
    } else {
      return {
        id: '',
        displayName: '',
        imageMap: {
          value: {
            value: {
              areas: [],
            },
          },
        },
        resourceUrl: '',
        sortKey: inRiverResource.sortKey || 0,
      };
    }
  }
}

module.exports = InRiverProcessor;
