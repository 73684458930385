import React, { Component } from 'react';

import breadcrumbs from './breadcrumbs';
import SearchComponent from '../components/search/Search';

class Search extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { location, site } = this.props;

    return (
      <SearchComponent
        breadcrumbs={breadcrumbs('SEARCH', location)}
        search={location.search}
        site={site}
      />
    );
  }
}

export default Search;
